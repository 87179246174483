_.ui = {};
_.ui.mask = function(config) {
    config = config || {}
    if (!config.multi && (!config.ele || $(config.ele).find('.shadow').length > 0) && $('.shadow').length > 0) {
        return
    }
    var mask = '<div class="shadow"><div class="mask"><div class="spin"></div></div></div>'
    if (!config.ele) {
        mask = $(mask)
        mask.css({
            'line-height': $('body').height() + 'px'
        })
        $('body').append(mask)
    } else if (config.only) {
        var ele = $(config.ele)
        var top = ele.offset().top
        var height = ele.height()
        var mask1 = $(mask)
        var mask2 = $(mask)
        mask1.css({
            'position': 'absolute',
            'top': '0px',
            'height': top + 'px'
        });
        mask2.css({
            'position': 'absolute',
            'top': (top + height) + 'px',
            'height': ($('body').height() - top - height) + 'px'
        });
        ele.append(mask1).append(mask2)
    } else {
        var ele = $(config.ele)
        mask = $(mask)
        var width = ele.width()
        var height = ele.height()
        mask.css({
            'position': 'absolute',
            'width': width + 'px',
            'height': height + 'px',
            'line-height': height + 'px'
        })
        ele.append(mask)
    }
}
_.ui.unmask = function(config) {
    config = config || {};
    if(config.ele){
        $(config.ele).find(".shadow").remove();
        return;
    }
    $(".shadow").remove();
}
_.ui.notify = function(config) {
    toastr.clear()
    $("#toast-container").remove()
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "positionClass": "toast-top-center",
        "onclick": null,
        "showDuration": "1000",
        "hideDuration": "1000",
        "timeOut": "3000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
    toastr[config.type || 'success'](config.message)
}
_.ui.confirm = function(config, success, fail) {
    config = config || {}
    config = _.util.merge({
        innerHTML: ''
    }, config)

    config.positiveBtnDisplay = config.positiveBtnDisplay || 'inline-block'

    var positiveBtnMsg = config.positiveBtnMsg || esapp.msg.get('confirm')
    var cancelBtnMsg = config.cancelBtnMsg || esapp.msg.get('cancel')
    config.innerHTML = '<div class="popup-common">' + config.innerHTML + '</div><div class="clear"></div><div class="main-popup-error" style="display: none"><div class="popup-error"></div><div class="popup-warn"></div></div><div class="clear"></div><div class="popup-btn">' +
        '<span style="margin-right: 8px;"><a href="javascript:void(0);" class="btn btn-default btn-reverse">' + cancelBtnMsg + '</a></span>' +
        '<span><a href="javascript:void(0);" class="btn btn-primary" style="display: ' + config.positiveBtnDisplay + ';">' + positiveBtnMsg + '</a></span><div class="clear"></div></div>'
    config.auto_hide = false
    config.auto_hide_default = false
    var ret = _.ui.popup(config)
    if (config.success) {
        $('.popup .popup-btn .btn-primary').click(function(e) {
            if (!config.success()) {
                return
            }
            $('.popup').remove()
            if (!config.hasCallbackMask) {
                _.ui.unmask()
            }
            e.preventDefault()
                // return false
        })
    }
    $('.popup .popup-btn .btn-reverse').click(function(e) {
        if (config.fail && !config.fail()) {
            return
        }

        $('.popup').remove()
        _.ui.unmask()
        e.preventDefault()
            // return false
    })
    return ret
}

_.ui.alert = function(config, isHyperlink) {
    config = config || {}
    config = _.merge({
        title: esapp.msg.get('defaultTitle'),
        auto_hide: true
    }, config)
    var key = config.key
    var msg = esapp.msg.get(key)
    var cls = ''
    var auto_hide = config.auto_hide
    if (config.type == 'error') {
        cls = 'class="error alertWhiteSpacePre"'
        auto_hide = false
    }
    if (isHyperlink) {
        var message = '<p ' + cls + '><a href="' + config.hyperlink + '">' + msg + '</a></p>'
    } else {
        var message = '<p ' + cls + '>' + msg + '</p>'
    }

    _.ui.popup({
        afteresclose: config.afteresclose,
        after_callback: config.after_callback,
        callback: config.callback,
        title: config.title,
        auto_hide: auto_hide,
        innerHTML: message
    })
}

_.ui.popup = function(config) {
    config = config || {}
    config = _.merge({
        title: esapp.msg.get('defaultTitle'),
        auto_hide_default: true,
        close: true,
        center: true
    }, config)
    _.client_height = document.documentElement.clientHeight
    _.client_width = document.documentElement.clientWidth
    var render = function() {
        $('.popup').remove()
        var minHeight = _.client_height - 70
        var minWidth = 300
        var top = (_.client_height - minHeight) / 2 - 10
        var left = (_.client_width - minWidth) / 2
        var div = $('<div class="popup"><div class="popup-title"></div><div class="clear"></div></div>')
        var time_out
        if (!config.title) {
            config.title = "&nbsp;"
        }
        div.children('.popup-title').html(config.title)
            // if (config.close) {
        if (!config.closestyle) {
            config.closestyle = ''
        }
        div.children('.popup-title').append('<button type="button" class="close" ><i class="iconfont icon-close"></i></button>')
            // }
        if (!config.auto_hide && config.auto_hide_default && !config.hide_buttons) {
            config.innerHTML += '<div class="popup-btn">' +
                '<div style="margin:auto;float: none;"><a href="javascript:void(0);" class="btn btn-primary btn-close">' + esapp.msg.get("close") + '</a></div></div>'
        }
        if (config.innerHTML) {
            div.append($('<div class="popup-content">' + config.innerHTML + '</div>'))
        }
        div.css({
            'top': top + "px",
            // 'left': left + "px",
            'min-width': minWidth + 'px',
            'width': config.width,
            'margin': '0 auto'
        })
        if (config.css) {
            div.css(config.css)
        }
        _.ui.mask()
        var close_func = function(e) {
            if (time_out) {
                clearTimeout(time_out)
            }
            if (config.before_callback) {
                var result = config.before_callback()
                if (!result) {
                    return
                }
            }

            if (config.after_callback) {
                config.after_callback()
            }
            $(document).trigger('click')
            $('.popup').remove()
            _.ui.unmask()
            if (config.afteresclose) {
                config.afteresclose()
            }
            e.preventDefault()
            return false
        }
        $('body').append(div)
        if (config.before_show) {
            config.before_show()
        }
        // if (config.close) {
        $('.popup .close').click(close_func)
            // }
        if (!config.auto_hide) {
            $('.popup .popup-btn .btn-close').click(close_func)
        }
        if (config.callback) {
            $('.popup').show(function() {
                config.callback()
            });
        } else {
            $('.popup').show()
        }
        var width = $('.popup').width()
        if (config.center) {
            var popup = $('.popup')
            var height = popup.height()
            popup.css({
                top: ((_.client_height - height) / 2) + 'px',
                left: ((_.client_width - width) / 2) + 'px'
            })
        }
        if (config.auto_hide) {
            time_out = setTimeout(function() {
                $('.popup').remove()
                _.ui.unmask()
            }, 2000);
        }
        var pop = $('.popup')
        pop.data('value', width)
        $('.popup-title').bind("mousedown", function(event) {
            var offset_x = $('.popup')[0].offsetLeft
            var offset_y = $('.popup')[0].offsetTop
            var offset_width = $('.popup')[0].offsetWidth
            var offset_height = $('.popup')[0].offsetHeight
            var mouse_x = event.pageX
            var mouse_y = event.pageY

            $(document).bind("mousemove", function(ev) {
                var _x = ev.pageX - mouse_x
                var _y = ev.pageY - mouse_y
                var now_x = (offset_x + _x)
                var now_y = (offset_y + _y)
                if (now_x <= 0) {
                    now_x = 0
                } else if (now_x >= _.client_width - offset_width) {
                    now_x = _.client_width - offset_width
                }
                if (now_y <= 0) {
                    now_y = 0
                } else if (now_y >= _.client_height - offset_height) {
                    now_y = _.client_height - offset_height
                }
                pop.css({
                    top: now_y + "px",
                    left: now_x + "px"
                })
            })
        })
        $(document).bind("mouseup", function() {
            $(document).unbind("mousemove");
        })
        $('.popup-title').dblclick(function() {
            var pop = $('.popup')
            var w = pop.outerWidth()
            if (w < _.client_width) {
                pop.css({
                    top: 0,
                    left: 0,
                    width: _.client_width,
                    height: _.client_height
                })
            } else {
                w = pop.data('value')
                pop.css({
                    width: w,
                    height: 'auto'
                })
                _.ui.popup.auto_position()
            }
        })
    }
    render();
}
_.ui.popup.auto_position = function() {
    var div = $('.popup')
    if (div.length <= 0) {
        return
    }
    _.client_height = document.documentElement.clientHeight
    _.client_width = document.documentElement.clientWidth

    var height = div.outerHeight()
    var width = div.outerWidth()

    var top = (_.client_height - height) / 2 - 10
    var left = (_.client_width - width) / 2

    if (top < 0) {
        top = 0
    }

    div.css({
        'top': top + "px",
        'left': left + "px"
    })
    div.data('value', width)
    var popup_common = div.find('.popup-content')
    if (popup_common && popup_common.length > 0) {
        if (_.client_height < height) {
            popup_common.css('max-height', (height - 70) + 'px')
            popup_common.css('height', (_.client_height - 70) + 'px')
        }
    }
}

_.ui.jsGridTableBar = function(config) {
    var target = $(config.table)
    if (!target.length) return
    target.parent().find('.tableBar').remove()
    target.after('<div class="tableBar"></div>')
    var bar = target.parent().find('.tableBar')

    var init = function() {
        var containerWidth = target.width();
        var tableWidth = target.find('table').width()
        bar.html('').append('<div></div>').css({
            width: containerWidth
        }).find('div').css({
            width: tableWidth
        })
        scrollInit();
    }
    var scrollInit = function() {
        var scrollTop = document.body.scrollTop ||document.documentElement.scrollTop;
        if (scrollTop + $(window).height() > target.offset().top + target.height()) {
            target.parent().find('.tableBar').css("visibility", "hidden");
        } else {
            target.parent().find('.tableBar').css("visibility", "visible");
        }
    }

    target.scroll(function(e) {
        var left = target.scrollLeft()
        bar.scrollLeft(left)
        target.parent().find('.jsgrid-grid-header .headerFrozenLeft').css({
            left: left
        })
        target.parent().find('.jsgrid-grid-header .headerFrozenRight ').css({
            right: -left
        })
    })

    bar.scroll(function(e) {
        target.scrollLeft(bar.scrollLeft())
    })

    init();
    $(window).resize(init)
    $(window).scroll(function(e) {
        scrollInit(); //模拟滚动的展现和隐藏
    })
}

//兼容  最外层统一class  --> js-tableBar
_.ui.gridTableBar = function(config) {
    var target = $(config.table),
        elParent = target.parents('.js-tableBar');
    if (!target.length) return
    target.parent().find('.tableBar').remove()
    target.after('<div class="tableBar"></div>')
    var bar = target.parents('.js-tableBar').find('.tableBar')

    var init = function() {
        var containerWidth = elParent.width();
        var tableWidth = target.width();
        bar.html('').append('<div></div>').css({
            width: containerWidth
        }).find('div').css({
            width: tableWidth
        })
        scrollInit();
    }
    var scrollInit = function() {
        if ($(document).scrollTop() + $(window).height() > target.offset().top + target.height()) {
            target.parent().find('.tableBar').css("visibility", "hidden");
        } else {
            target.parent().find('.tableBar').css("visibility", "visible");
        }
    }
    elParent.scroll(function(e) {
        setTimeout(function(){
            var left = $(e.target).scrollLeft()
            bar.scrollLeft(left)
        },100);
    })

    bar.scroll(function(e) {
        setTimeout(function(){
            elParent.scrollLeft($(e.target).scrollLeft())
        },100)
    })

    init();
    $(window).resize(init)
    $(window).scroll(function(e) {
        scrollInit(); //模拟滚动的展现和隐藏
    })
}

_.ui.jsGridTableFixed = function(config) {
    var fixed = function() {
        var outer = $(config.outer)
        if (!outer.parents('div.tab-pane').hasClass('active')) {
            return
        }
        var scrollTop = $(window).scrollTop()
        var headerHeight = $(".page-header.navbar.navbar-fixed-top").height()
        var outerTop = outer.offset().top
        var controlH = $(outer.parent().find('.control-buttons-tab')).outerHeight()
        var top
        if (scrollTop + headerHeight + controlH > outerTop) {
            top = scrollTop + headerHeight + controlH - outerTop
            if ($(window).width() < 768) {
                top -= headerHeight
            }
        } else {
            top = 0
        }
        outer.find('.jsgrid-grid-header').css({
            top: top,
            zIndex: top == 0 ? 9989 : 9991 // 当上下滚动的时候，控制 显示的checkbox
        })
        outer.parent().find('.control-buttons-tab').css({
            top: top
        })
    }
    $(window).scroll(function(e) {
        fixed(); //固定表头和操作
    })
}

_.ui.popupVesselVoyage = function() {
    Handlebars.registerHelper('stringify', function(list) {
        if (list && Object.prototype.toString.call(list) == '[object Array]') return JSON.stringify(list)
       
        return ''
    })
    
    $(".vesselvoyage:visible").unbind('click').on('click', function(e) {
        e.stopPropagation();
        var list = this.innerHTML.trim().split('  ');
        var vessel = list[0] || '';
        var voyage = list[1] || '';
        var template = Handlebars.compile($('#vesselvoyage-click-template').html())
        _.ui.confirm({
            title: '鸭嘴兽',
            innerHTML: template(),
            auto_hide: false,
            cancelBtnMsg: '关闭',
            before_show: function() {
                _.util.ajax_get('/carrier/queryCarrierInfo', {
                    vessel: vessel,
                    voyage: voyage
                }, function(res) {
                    var result = res
                    var portToCallHoverFunction = function() {
                        var $domTitle = $('<div>');
                        var hoverFn = function(e) {
                            var $thisDom = $(this)
                            var text = $thisDom.data('value')
                            if (text) {
                                var html = ''
                                text.forEach(function(item) {
                                    html += '<div class="hoverPortToCallItem">' + item + '</div>'
                                })

                                $domTitle = $('<div id="hover-notation"><div class="hoverPortToCall">' + html + '</div></div>').addClass('notation').css({
                                    position: 'fixed',
                                    top: $thisDom.offset().top - $(document).scrollTop() + $thisDom.height(),
                                    left: $thisDom.offset().left,
                                    'z-index': 10003,
                                    'line-height': 1.3
                                })
                                $('body').append($domTitle)
                            }
                        }
                        $('.portOfCall').hover(hoverFn, function() {
                            $domTitle.remove()
                        })
                    }
                    $('#order-info .toggle-btn').unbind('click').bind('click', function() {
                        $('#order-info').find('.toggle-btn').addClass('reset-btns');
                        $('#order-info').find('.toggle-btn').removeClass('chose-btns');
                        $(this).removeClass('reset-btns');
                        $(this).addClass('chose-btns');
                        $('#result-form1 .admin-table').remove();
                        $('.no-box').remove();

                        var template
                        switch (this.id) {
                            case 'clientBtn':
                                template = Handlebars.compile($('#row-info-template2-vesselvoyage').html())
                                break;
                            case 'plateNumberBtn':
                                template = Handlebars.compile($('#row-info-template1-vesselvoyage').html())
                                break;
                            case 'driverBtn':
                                template = Handlebars.compile($('#row-info-template3-vesselvoyage').html())
                                break;
                            default:
                                break;
                        }
                        $('#result-form1').html(template(result))
                        portToCallHoverFunction()
                    })

                    var initTemplate = Handlebars.compile($('#row-info-template2-vesselvoyage').html())
                    $('#result-form1').html(initTemplate(res));
                    portToCallHoverFunction()
                })
                // $('.btn-primary').hide()
               $('.popup .popup-btn .btn-primary').hide();
            }
        }, function() {

        }, function() {
            $('.btn-primary').show()
            $('.popup').remove()
            _.ui.unmask()
            e.preventDefault()
        })
    })
}


/*可以改进啊。。。哎。。。*/
_.ui.popupVesselVoyageFunction = function(vessel, voyage, portId,flag) {
    Handlebars.registerHelper('stringify', function(list) {
        if (list && Object.prototype.toString.call(list) == '[object Array]') return JSON.stringify(list)
        return ''
    })
    var template = Handlebars.compile($('#vesselvoyage-click-template').html())
    _.ui.confirm({
        title: '鸭嘴兽',
        innerHTML: template(),
        auto_hide: false,
        cancelBtnMsg: '关闭',
        before_show: function() {
            _.util.ajax_get('/carrier/queryCarrierInfo', {
                vessel: vessel || '',
                voyage: voyage || '',
                portId: portId
            }, function(res) {
                var result = res
                var portToCallHoverFunction = function() {
                    var $domTitle = $('<div>');
                    var hoverFn = function(e) {
                        var $thisDom = $(this)
                        var text = $thisDom.data('value')
                        if (text) {
                            var html = ''
                            text.forEach(function(item) {
                                html += '<div class="hoverPortToCallItem">' + item + '</div>'
                            })

                            $domTitle = $('<div id="hover-notation"><div class="hoverPortToCall">' + html + '</div></div>').addClass('notation').css({
                                position: 'fixed',
                                top: $thisDom.offset().top - $(document).scrollTop() + $thisDom.height(),
                                left: $thisDom.offset().left,
                                'z-index': 10003,
                                'line-height': 1.3
                            })
                            $('body').append($domTitle)
                        }
                    }
                    $('.portOfCall').hover(hoverFn, function() {
                        $domTitle.remove()
                    })
                }
                $('#order-info .toggle-btn').unbind('click').bind('click', function() {
                    $('#order-info').find('.toggle-btn').addClass('reset-btns');
                    $('#order-info').find('.toggle-btn').removeClass('chose-btns');
                    $(this).removeClass('reset-btns');
                    $(this).addClass('chose-btns');
                    $('#result-form1 .admin-table').remove();
                    $('.no-box').remove();

                    var template
                    switch (this.id) {
                        case 'clientBtn':
                            template = Handlebars.compile($('#row-info-template2-vesselvoyage').html())
                            break;
                        case 'plateNumberBtn':
                            template = Handlebars.compile($('#row-info-template1-vesselvoyage').html())
                            break;
                        case 'driverBtn':
                            template = Handlebars.compile($('#row-info-template3-vesselvoyage').html())
                            break;
                        default:
                            break;
                    }
                    $('#result-form1').html(template(result))
                    portToCallHoverFunction()
                })

                var initTemplate = Handlebars.compile($('#row-info-template2-vesselvoyage').html())
                $('#result-form1').html(initTemplate(res));
                portToCallHoverFunction()
            })
            if(flag){
                $('.popup .popup-btn .btn-primary').hide();
            }else {
                $('.btn-primary').hide() 
            }
            
        }
    }, function() {

    }, function() {
        $('.btn-primary').show()
        $('.popup').remove()
        _.ui.unmask()
        e.preventDefault()
    })
}

_.ui.sort_table_list = function(config) {
    var self = this;
    config.sortable = config.sortable || Sortable
    self.config = config;
    self.table = {
        createUI: function(config) {
            var commonHead = ['<ul class="drag-move">',
                '<li><div class="width-1">序号</div><div class="width-2">字段名</div><div class="width-1">显示</div><div class="width-1">导出</div></li>',
                '</ul>'
            ].join('');
            var arr = config.tableHeadInfo;
            var maxNum = 0;
            if (arr.length > 45) {
                maxNum = 4;
            } else if (arr.length > 30) {
                maxNum = 3;
            } else if (arr.length > 11) {
                maxNum = 2;
            } else {
                maxNum = 1;
            }
            var headHtml = "";
            for (var i = 0; i < maxNum; i++) {
                headHtml += commonHead
            }
            var bodyHtml = "";
            var result = [];
            var maxLen = arr.length % maxNum == 0 ? arr.length / maxNum : (arr.length - arr.length % maxNum) / maxNum + 1;
            config.maxLen = maxLen
            config.maxNum = maxNum
            for (var i = 0, len = arr.length; i < len; i += maxLen) {
                result.push(arr.slice(i, i + maxLen));
            }

            result.forEach(function(newArr, x) {
                var sortHtml = "<ul class='drag-move sort-" + config.id + "'>";
                newArr.forEach(function(v, i) {
                    var li = ["<li data-key='" + v.columnKey + "' data-name='" + v.columnName + "' data-width='" + v.width + "' >",
                        '<div class="width-1 drag-handle"><span class="num">' + v.seq + '</span></div>',
                        '<div class="width-2 drag-handle">' + v.columnName + '</div>',
                        '<div class="width-1"><input name="needDisplay" type="checkbox" ' + (v.needDisplay ? 'checked' : '') + ' ' + (v.mustDisplay ? 'disabled' : '') + '  /></div>',
                        '<div class="width-1"><input name="needExport" type="checkbox" ' + (v.needExport ? 'checked' : '') + ' /></div>',
                        // '<div class="width-3 edit"> <span class="content" title=""><span class="edit-text">' + (v.width || '') + '</span><span class="img"></span></span><span class="input hide"><input name="width" type="text" maxlength="3" value="' + (v.width || '') + '"  /> </span></div>',
                        '</li>'
                    ].join('');
                    sortHtml += li;
                })
                sortHtml += "</ul>";
                bodyHtml += sortHtml
            })

            var html = ['<div class="drag">',
                '<div class="drag-move-head">', headHtml, '</div>',
                '<div class="drag-move-body">', bodyHtml, '</div>',
                '</div>'
            ].join('');

            return html;
        },
        renderUI: function(key) {
            //处理数据。
            var info = self.table.getList();
            var infoObj = {};
            info.forEach(function(v, i) {
                infoObj[v.columnKey] = v;
            })
            self.config.tableHeadInfo.forEach(function(v, i) {
                v = $.extend(v, infoObj[v.columnKey]);
                if (v.columnName.indexOf('>') >= 0) {
                    var item = v.columnName;
                    item = item.split('<span class="bgd_yellow">').join("");
                    item = item.split('</span>').join("");
                    v.columnName = item;
                }

                if (key && (v.columnName.indexOf(key) >= 0)) {
                    v.columnName = _.util.highLightbdg(v.columnName, key);
                }
            })

            //排序
            self.config.tableHeadInfo.sort(_.util.sortCompare('seq'));

            $('.popup .popup-common #table-list').html(this.createUI(self.config))
            $('.popup .popup-common #table-list .drag-move-body').css({
                minHeight: (self.config.maxLen + 1) * ($(".drag-move li").eq(0).height() + 1), //留一点高度给拖拽
                minWidth: 190 * config.maxNum - 5 //弹窗容器的渲染 有问题。容器的宽度没有自适应
            })
            this.bindEvent();
        },
        bindEvent: function() {
            var _this = this;
            [].forEach.call(document.querySelector("#table-list").getElementsByClassName("sort-" + config.id), function(el) {
                config.sortable.create(el, {
                    group: 'table',
                    handle: '.drag-handle',
                    animation: 150,
                    onEnd: function(evt) {
                        $('#table-list .drag-move-body').find('li').each(function(i, v) {
                            $(v).find('span.num').html(i + 1);
                        })
                        _this.renderUI();
                    },
                });
            });
        },
        getList: function() {
            var arr = [];
            $("#table-list .drag-move-body li").each(function() {
                arr.push({
                    seq: $(this).find('.num').html(),
                    columnKey: $(this).data('key'),
                    columnName: $(this).data('name'),
                    needDisplay: $(this).find('[name=needDisplay]').is(':checked'),
                    needExport: $(this).find('[name=needExport]').is(':checked'),
                    width: $(this).data('width')
                })
            });
            return arr;
        }
    }

    self.condition = {
        createUI: function(config) {
            var commonHead = ['<ul class="drag-move">',
                '<li><div class="width-1">序号</div><div class="width-2">字段名</div><div class="width-1">显示</div></li>',
                '</ul>'
            ].join('');
            var arr = config.conditionInfo;
            var maxNum = 0;
            if (arr.length > 45) {
                maxNum = 4;
            } else if (arr.length > 30) {
                maxNum = 3;
            } else if (arr.length > 15) {
                maxNum = 2;
            } else {
                maxNum = 1;
            }
            var headHtml = "";
            for (var i = 0; i < maxNum; i++) {
                headHtml += commonHead
            }
            var bodyHtml = "";
            var result = [];
            var maxLen = arr.length % maxNum == 0 ? arr.length / maxNum : (arr.length - arr.length % maxNum) / maxNum + 1;
            for (var i = 0, len = arr.length; i < len; i += maxLen) {
                result.push(arr.slice(i, i + maxLen));
            }

            result.forEach(function(newArr, x) {
                var sortHtml = "<ul class='drag-move sort-" + config.id + "'>";
                newArr.forEach(function(v, i) {
                    var li = ["<li data-key='" + v.columnKey + "' data-name='" + v.columnName + "' data-width='" + v.width + "' >",
                        '<div class="width-1 drag-handle"><span class="num">' + v.seq + '</span></div>',
                        '<div class="width-2 drag-handle">' + v.columnName + '</div>',
                        '<div class="width-1"><input name="needDisplay" type="checkbox" ' + (v.needDisplay ? 'checked' : '') + ' ' + (v.mustDisplay ? 'disabled' : '') + '  /></div>',
                        '</li>'
                    ].join('');
                    sortHtml += li;
                })
                sortHtml += "</ul>";
                bodyHtml += sortHtml
            })

            var html = ['<div class="drag">',
                '<div class="drag-move-head">', headHtml, '</div>',
                '<div class="drag-move-body">', bodyHtml, '</div>',
                '</div>'
            ].join('');

            return html;
        },
        renderUI: function(key) {
            //处理数据。
            var info = this.getList();
            var infoObj = {};
            info.forEach(function(v, i) {
                infoObj[v.columnKey] = v;
            })
            self.config.conditionInfo.forEach(function(v, i) {
                v = $.extend(v, infoObj[v.columnKey]);
                if (v.columnName.indexOf('>') >= 0) {
                    var item = v.columnName;
                    item = item.split('<span class="bgd_yellow">').join("");
                    item = item.split('</span>').join("");
                    v.columnName = item;
                }
                if (key && (v.columnName.indexOf(key) >= 0)) {
                    v.columnName = _.util.highLightbdg(v.columnName, key);
                }
            })

            //排序
            self.config.conditionInfo.sort(_.util.sortCompare('seq'));

            $('.popup .popup-common #condition').html(this.createUI(self.config))

            $('.popup .popup-common #condition .drag-move-body').css({
                minHeight: (self.config.maxLen + 1) * ($(".drag-move li").eq(0).height() + 1), //留一点高度给拖拽
                minWidth: 190 * config.maxNum - 5 //弹窗容器的渲染 有问题。容器的宽度没有自适应
            })
            this.bindEvent();
        },
        bindEvent: function() {
            var _this = this;
            [].forEach.call(document.querySelector("#condition").getElementsByClassName("sort-" + config.id), function(el) {
                config.sortable.create(el, {
                    group: 'table',
                    handle: '.drag-handle',
                    animation: 150,
                    onEnd: function(evt) {
                        $('#condition .drag-move-body').find('li').each(function(i, v) {
                            $(v).find('span.num').html(i + 1);
                        })
                        _this.renderUI();
                    },
                });
            });
        },
        getList: function() {
            var arr = [];
            $("#condition .drag-move-body li").each(function() {
                arr.push({
                    seq: $(this).find('.num').html(),
                    columnKey: $(this).data('key'),
                    columnName: $(this).data('name'),
                    needDisplay: $(this).find('[name=needDisplay]').is(':checked'),
                })
            });
            return arr;
        }
    }

    self.createUIAll = function() {
        var innerHtml = [
            '<div id="switch-container">',
            '<div id="tap-container">',
            '<ul class="switch-tap">',
            '<li class="active"><a href="#table-list">列表</a></li>',
            '<li><a href="#condition">条件</a></li>',
            '<li class="inputdiv"><input id="search_input" placeholder="请输入查询内容"/></li>',
            '<li class="float-right"><div class="drag-info-tap">可拖拽序号和字段名调整顺序！</div></li>',
            '</ul>',
            '</div>',
            '<div id="table-list" class="tap-panel active">' + self.table.createUI(self.config) + '</div>',
            '<div id="condition" class="tap-panel">' + self.condition.createUI(self.config) + '</div>',
            '</div>'
        ].join('')
        return innerHtml
    }


    var innerHtml = ""
    if (self.config.tableHeadInfo && self.config.conditionInfo) {
        innerHtml = self.createUIAll()
    } else {
        innerHtml = '<div id="switch-container">' + '<div id="tap-container">' + '<ul class="switch-tap">' + '<li class="inputdiv"><input id="search_input" placeholder="请输入查询内容"/></li>' + '</ul>' + '</div>' + '<div id="table-list">' + self.table.createUI(self.config) + '</div>' + '</div>'
    }


    _.ui.confirm({
        title: '设置',
        innerHTML: innerHtml,
        auto_hide: false,
        before_show: function() {
            self.table.bindEvent();
            var hrefid = '#table-list';
            $('.switch-tap li').unbind('click').on('click', function() {
                var id = $(this).find("a").attr('href')
                if (id) {
                    hrefid = id;
                    $('#switch-container .switch-tap li').removeClass('active')
                    $(this).addClass('active')
                    $('#switch-container .tap-panel').hide()
                    $(id).show()
                }
                var key = $('#search_input').val()
                if (hrefid == '#table-list') {
                    self.table.renderUI(key);
                } else {
                    self.condition.renderUI(key);
                }

            })
            if (self.config.conditionInfo) {
                var marginleft = 0;
                if (config.maxNum == 1) {
                    marginleft = 126;
                } else {
                    marginleft = (190 * config.maxNum - 5) / 2 - 180;
                }

                $('.inputdiv').css({
                    'margin-left': marginleft
                })
            } else {
                $('.inputdiv').css({
                    'margin-bottom': 5
                })
            }
            $('#search_input').on('keyup paste', function() {
                var key = $('#search_input').val()
                if (hrefid == '#table-list') {
                    self.table.renderUI(key);
                } else {
                    self.condition.renderUI(key);
                }
            })

            //单独的样式处理
            if (self.config.tableHeadInfo && self.config.conditionInfo) {
                $('.popup .popup-btn').css({
                    paddingTop: 12,
                })
            } else {
                $('.popup .popup-btn').css({
                    paddingTop: 12,
                    position: 'relative'
                }).append('<div class="drag-info">可拖拽序号和字段名调整顺序！</div>')
            }

            $('.popup .popup-common .drag-move-body').css({
                minHeight: (self.config.maxLen + 1) * ($(".drag-move li").eq(0).height() + 1), //留一点高度给拖拽
                minWidth: 190 * config.maxNum - 5 //弹窗容器的渲染 有问题。容器的宽度没有自适应
            })



        },
        success: function() {
            var tableList = self.table.getList();
            tableList.forEach(function(v, i) {
                if (v.columnName.indexOf('>') >= 0) {
                    var item = v.columnName;
                    item = item.split('<span class="bgd_yellow">').join("");
                    item = item.split('</span>').join("");
                    v.columnName = item;
                }
            })
            var conditionList = self.condition.getList();
            conditionList.forEach(function(v, i) {
                if (v.columnName.indexOf('>') >= 0) {
                    var item = v.columnName;
                    item = item.split('<span class="bgd_yellow">').join("");
                    item = item.split('</span>').join("");
                    v.columnName = item;
                }
            })

            config.successCallBack({
                tableList: tableList,
                tableName: self.config.tableName,
                conditionList: conditionList,
                conditionName: self.config.conditionTableName
            });
            return true;

        }
    })
}


_.ui.wheelzoomCanvas = function(config) {
    var canvas = config.canvas
    var rotateBtn = $(config.canvas).parent().find('.rotate')[0]
    var enlargeBtn = $(config.canvas).parent().find('.enlarge')[0]
    var narrowBtn = $(config.canvas).parent().find('.narrow')[0]
    var context = canvas.getContext('2d')
    var img = new Image()
    var clientWidth = config.clientWidth
    var clientHeight = config.clientHeight
    var timer
    img.src = config.src

    function trackTransforms(context) {
        var svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg')
        var xform = svg.createSVGMatrix()
        context.getTransform = function() {
            return xform
        }

        var savedTransforms = []
        var save = context.save
        context.save = function() {
            savedTransforms.push(xform.translate(0, 0))
            return save.call(context)
        }
        var restore = context.restore
        context.restore = function() {
            xform = savedTransforms.pop()
            return restore.call(context)
        }

        var scale = context.scale
        context.scale = function(sx, sy) {
            xform = xform.scaleNonUniform(sx, sy)
            return scale.call(context, sx, sy)
        }
        var rotate = context.rotate
        context.rotate = function(radians) {
            xform = xform.rotate(radians * 180 / Math.PI)
            return rotate.call(context, radians)
        }
        var translate = context.translate
        context.translate = function(dx, dy) {
            xform = xform.translate(dx, dy)
            return translate.call(context, dx, dy)
        }
        var transform = context.transform
        context.transform = function(a, b, c, d, e, f) {
            var m2 = svg.createSVGMatrix()
            m2.a = a
            m2.b = b
            m2.c = c
            m2.d = d
            m2.e = e
            m2.f = f
            xform = xform.multiply(m2)
            return transform.call(context, a, b, c, d, e, f)
        }
        var setTransform = context.setTransform
        context.setTransform = function(a, b, c, d, e, f) {
            xform.a = a
            xform.b = b
            xform.c = c
            xform.d = d
            xform.e = e
            xform.f = f
            return setTransform.call(context, a, b, c, d, e, f)
        }
        var pt = svg.createSVGPoint()
        context.transformedPoint = function(x, y) {
            pt.x = x
            pt.y = y
            return pt.matrixTransform(xform.inverse())
        }
    }

    var Loading = function(canvas, options) {
        this.canvas = canvas
        this.options = options
    }
    Loading.prototype = {
        constructor: Loading,
        show: function() {
            var canvas = this.canvas,
                begin = this.options.begin,
                old = this.options.old,
                lineWidth = this.options.lineWidth,
                canvasCenter = { x: canvas.width / 2, y: canvas.height / 2 },
                ctx = canvas.getContext("2d"),
                color = this.options.color,
                num = this.options.num,
                angle = 0,
                lineCap = this.options.lineCap,
                CONST_PI = Math.PI * (360 / num) / 180
            timer = setInterval(function() {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                for (var i = 0; i < num; i += 1) {
                    ctx.beginPath()
                    ctx.strokeStyle = color[num - 1 - i]
                    ctx.lineWidth = lineWidth
                    ctx.lineCap = lineCap
                    ctx.moveTo(canvasCenter.x + Math.cos(CONST_PI * i + angle) *
                        begin, canvasCenter.y + Math.sin(CONST_PI * i + angle) * begin)
                    ctx.lineTo(canvasCenter.x + Math.cos(CONST_PI * i + angle) *
                        old, canvasCenter.y + Math.sin(CONST_PI * i + angle) * old)
                    ctx.stroke()
                    ctx.closePath()
                }
                angle += CONST_PI
            }, 100)
        },
        hide: function() {
            clearInterval(timer)
        }
    }
    var options = {
        num: 8,
        begin: 20,
        old: 40,
        lineWidth: 10,
        lineCap: "round",
        color: ["rgb(0, 0, 0)", "rgb(20, 20, 20)", "rgb(40, 40, 40)", "rgb(60, 60, 60)", "rgb(80, 80, 80)",
            "rgb(100, 100, 100)", "rgb(120, 120, 120)", "rgb(140, 140, 140)"
        ]
    }
    var loading = new Loading(canvas, options)
    loading.show()


    function calculate(imgHeight, imgWidth, frameHeight, frameWidth) {
        var ratio = imgHeight / imgWidth - frameHeight / frameWidth
        var imgRatio = imgHeight / imgWidth
        var zoomImg = {}
        if (ratio > 0) {
            zoomImg.height = frameHeight
            zoomImg.width = zoomImg.height / imgRatio
        } else {
            zoomImg.width = frameWidth
            zoomImg.height = zoomImg.width * imgRatio
        }
        return zoomImg
    }

    function redraw(img) {
        var zoomImg = calculate(img.height, img.width, clientHeight, clientWidth)
        var left = (clientWidth - zoomImg.width) / 2
        var top = (clientHeight - zoomImg.height) / 2
        var p1 = context.transformedPoint(0, 0)
        var p2 = context.transformedPoint(clientWidth, clientHeight)
        context.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y)
        context.drawImage(img, left, top, zoomImg.width, zoomImg.height)
    }

    trackTransforms(context)

    img.onload = function() {
        loading.hide()
        canvas.width = clientWidth
        canvas.height = clientHeight
        var image = this
        redraw(image)

        var lastX = this.width / 2,
            lastY = this.height / 2
        var dragStart, dragged
        var scaleFactor = 1.1
        var zoom = function(clicks) {
            var pt = context.transformedPoint(lastX, lastY)
            context.translate(pt.x, pt.y)
            var factor = Math.pow(scaleFactor, clicks)
            context.scale(factor, factor)
            context.translate(-pt.x, -pt.y)
            redraw(image)
        }
        var handleScroll = function(evt) {
            var delta
            if (evt.type == 'click') {
                lastX = clientWidth / 2
                lastY = clientHeight / 2
                    // 修改兼容性问题  evt.toElement  ---> evt.target
                if ($(evt.target).hasClass('fa-search-minus')) {
                    delta = -3
                } else if ($(evt.target).hasClass('fa-search-plus')) {
                    delta = 3
                }
            } else {
                delta = evt.wheelDelta ? evt.wheelDelta / 40 : evt.detail ? -evt.detail : 0
            }
            if (delta) zoom(delta)
            return evt.preventDefault() && false
        }
        canvas.addEventListener('mousedown', function(evt) {
            document.body.style.mozUserSelect = document.body.style.webkitUserSelect = document.body.style.userSelect = 'none'
            lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft)
            lastY = evt.offsetY || (evt.pageY - canvas.offsetTop)
            dragStart = context.transformedPoint(lastX, lastY)
            dragged = false
            evt.preventDefault()
            evt.stopPropagation()
        }, false)
        canvas.addEventListener('mousemove', function(evt) {
            lastX = evt.offsetX || (evt.pageX - canvas.offsetLeft)
            lastY = evt.offsetY || (evt.pageY - canvas.offsetTop)
            dragged = true
            if (dragStart) {
                var pt = context.transformedPoint(lastX, lastY)
                context.translate(pt.x - dragStart.x, pt.y - dragStart.y)
                redraw(image)
            }
            evt.preventDefault()
            evt.stopPropagation()
        }, false)
        canvas.addEventListener('mouseup', function(evt) {
            dragStart = null
                //if (!dragged) zoom(evt.shiftKey ? -1 : 1)
        }, false)
        rotateBtn.addEventListener('click', function() {
                context.translate(clientWidth / 2, clientHeight / 2)
                context.rotate(90 * Math.PI / 180)
                context.translate(-clientWidth / 2, -clientHeight / 2)
                redraw(image)
            }, false)
            //saveBtn.addEventListener('click', function () {
            //	window.open(img.src)
            //}, false)
        enlargeBtn.addEventListener('click', handleScroll, false)
        narrowBtn.addEventListener('click', handleScroll, false)
        canvas.addEventListener('DOMMouseScroll', handleScroll, false)
        canvas.addEventListener('mousewheel', handleScroll, false)
    }
}

_.ui.show_container_img = function(target, imagePaths, closeMethod, conf) {
    $('.bd-attachment').remove()
    var imageLen = imagePaths.length
    var liHtml = ""
    var barHtml = ""
    var hasDel = false
        // 添加一个配置参数config配置自定义容器的一些属性
    var config = conf || {},
        windowWidth = config.windowWidth || 780,
        windowHeight = config.windowHeight || 400;
    var initDom = function() {}
    for (var i = 0; i < imageLen; i++) {
        liHtml +=
            "<li class='img-show'>" +
            "	<div class='pic-div'>" +
            "		<canvas></canvas>" +
            "		<a class='narrow'><i class='fa fa-search-minus noClose' aria-hidden='true'></i></a>" +
            "		<a class='save' href='" + imagePaths[i] + "' download ><i class='fa fa-floppy-o noClose' aria-hidden='true'></i></a>" +
            (config.delete ?"<a class='trash' style='left: 47.5%' data-path='" + imagePaths[i] + "'><i class='fa fa fa-trash noClose' aria-hidden='true' data-path='" + imagePaths[i] + "'></i></a>" :"") +
            "		<a class='rotate'><i class='fa fa fa-repeat noClose' aria-hidden='true'></i></a>" +
            "		<a class='enlarge'><i class='fa fa-search-plus noClose' aria-hidden='true'></i></a>" +
            "	</div>" +
            "</li>"
        barHtml += "<li class='bd-bottom-corner'></li>"
    }
    var imgHtml =
        '<div class="bd-attachment">' +
        '	<div class="attachment-img-header"></div>' +
        '	<div class="attachment-img js-attachment-img">' +
        '		<ul class="bd-img">' + liHtml +
        '		</ul>' +
        '		<div class="left-corner corner"><i class="fa fa-angle-left noClose"></i></div>' +
        '		<div class="right-corner corner"><i class="fa fa-angle-right noClose"></i></div>' +
        '	</div>' +
        '	<ul class="bottom-corner">' + barHtml +
        '	</ul>' +
        //'	<div class="triangle"></div>' +
        '	<div class="img-close"></div>' +
        '</div>'
    $('body').append(imgHtml)
    $('.bd-bottom-corner').eq(0).addClass('corner-current');
    //修改图片打开popup定位问题，改成自动计算 start
    var setImagesPopup = function() {
        var windowBoxWidth = $(window).outerWidth(),
            windowBoxHeight = $(window).outerHeight(),
            left = 0,
            top = 0,
            width = windowWidth,
            height = windowHeight;
        if (windowBoxWidth - width <= 0) {
            width = windowBoxWidth;
            left = 0
        } else {
            left = (windowBoxWidth - width) / 2;
        }
        if (windowBoxHeight - height <= 0) {
            height = windowBoxHeight;
            top = 0
        } else {
            top = (windowBoxHeight - height) / 2;
        }
        return {
            top: top,
            left: left,
            height: height - 20,
            width: width - 20
        }
    };

    //修改图片打开popup定位问题，改成自动计算 end

    //添加resize事件,重新设置图片打开popup定位 start
    $(window).resize(function() {
        if ($(".bd-attachment").is(":visible")) {
            var popupData = setImagesPopup();
            $('.bd-attachment').css({
                'width': popupData.width + "px",
                'height': popupData.height + "px",
                'left': popupData.left,
                'top': popupData.top,
                'position': 'fixed'
            });
            $.each($('.img-show canvas'), function(index, item) {
                _.ui.wheelzoomCanvas({
                    canvas: item,
                    src: imagePaths[index],
                    clientWidth: popupData.width,
                    clientHeight: popupData.height
                })
            })
        }
    });
    //添加resize事件,重新设置图片打开popup定位 end
    var imagesPopup = setImagesPopup();
    $('.bd-attachment').css({
        'width': imagesPopup.width + "px",
        'height': imagesPopup.height + "px",
        'left': imagesPopup.left,
        'top': imagesPopup.top,
        'position': 'fixed'
    });
    $('.bd-img').css({
        'width': $('.bd-attachment').width() * imageLen + 'px'
    });
    $('.bd-img li').css({
        'width': 100 / imageLen + '%',
        'textAlign': 'center',
        'overflow-y': 'auto'
    })
    $('.bd-bottom-corner').css({
        'width': 100 / imageLen + '%'
    })
    var active = 0
    var carousel = function(elClass, index) {
        var index = parseInt(index)
        var removeWidth = $('.bd-img li').width()
        $('.' + elClass).unbind().bind('click', function() {
            active += index
            if (active < 0) {
                active = imageLen - 1
            } else if (active > (imageLen - 1)) {
                active = 0
            }
            var left = removeWidth * active
            $('.bd-img').stop().animate({
                'left': '-' + left + 'px'
            })
            $('.bd-bottom-corner').removeClass('corner-current').eq(active).addClass('corner-current')
        })
    }
    carousel('left-corner', '-1')
    carousel('right-corner', '1')

    $('.img-close').click(function() {
        $('.bd-attachment').hide()
        var doneData = {
            hasDel: hasDel
        }
        closeMethod ? closeMethod(doneData) : "";
    })

    $('.img-show .trash').click(function(e) {
        _.ui.confirm({
            title: '鸭嘴兽',
            innerHTML: '是否删除此张图片',
            success: function() {
                hasDel = true
                if(imageLen == 1) {
                    config.delete(e.target.dataset.path, function() {
                        $('.bd-attachment').hide()
                        var doneData = {
                            hasDel: hasDel
                        }
                        closeMethod ? closeMethod(doneData) : "";
                    })
                }else {
                    var path = e.target.dataset.path
                    imagePaths.forEach(function(v, i) {
                        if(v == path) {
                            $('.img-show').eq(i).remove()
                            $('.bd-bottom-corner').eq(i).remove()
                            $('.bd-bottom-corner').css({
                                'width': 100 / (--imageLen) + '%'
                            })
                            active = 0
                            $('.bd-img').stop().animate({
                                'left': '0px'
                            })
                            $('.bd-bottom-corner').removeClass('corner-current').eq(0).addClass('corner-current')
                            config.delete(e.target.dataset.path)
                        }
                    })
                }
                return true
            }
        })
    })

    $.each($('.img-show canvas'), function(index, item) {
        _.ui.wheelzoomCanvas({
            canvas: item,
            src: imagePaths[index],
            clientWidth: imagesPopup.width,
            clientHeight: imagesPopup.height
        })
    });

    $('.bd-attachment').unbind('mousedown').bind("mousedown", function(event) {
        var offset_x = $('.bd-attachment')[0].offsetLeft
        var offset_y = $('.bd-attachment')[0].offsetTop
        var offset_width = $('.bd-attachment')[0].offsetWidth
        var offset_height = $('.bd-attachment')[0].offsetHeight
        var mouse_x = event.pageX
        var mouse_y = event.pageY
        var width = document.documentElement.clientWidth
        var height = document.documentElement.clientHeight
        var halfWidth = (width - offset_width) / 2
        $(document).unbind('mousemove').bind("mousemove", function(ev) {
            var _x = ev.pageX - mouse_x
            var _y = ev.pageY - mouse_y
            var now_x = (offset_x + _x)
            var now_y = (offset_y + _y)
            $('.bd-attachment').css({
                top: now_y + "px",
                left: now_x + "px"
            })
            ev.preventDefault();
        });
        event.preventDefault();
    })
    $(document).unbind('mouseup').bind("mouseup", function(event) {
        $(document).unbind("mousemove");
        event.preventDefault();
    })

    if (config.clickImagesClose) {}

}